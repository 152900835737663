/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../components/text';
import { Dashboard } from './dashboard';
import { Fouls } from './fouls';
import { Period } from './period';
import { Score } from './score';
import Team from './team';
import { TeamsName } from './teamsName';
import CircleArrowCogwheelIcon from "../../../../ud-ui/components/icon/circle-arrow-cogwheel-icon";
import classNames from "classnames";
import { Button, HideButton, MainBlock, OpenPanelWrapper } from "./style";
import useGameInfo from "../../hooks/useGameInfo";
import { resetGameInfo, syncGameInfo } from "../../../store/gameInfo/actions";
import { resetTime, syncTime } from "../../../store/time/actions";
import SyncIcon from "../../../../ud-ui/components/icon/sync-icon";
import supabase from "../../../../core/supabase/supabase";
import Indicator from "../components/indicator";
import JoystickIcon from "../../../../ud-ui/components/icon/joystick-icon";
import { selectIsHideControlPanel } from "../../../store/overlay/selectors";
import { setHideControlPanel } from "../../../store/overlay/overlay";
import ArrowBracketIcon from "../../../../ud-ui/components/icon/arrow-bracket-icon";

function Joystick() {
  const dispatch = useDispatch()
  const { match, isSynchronization } = useGameInfo()
  const [isJoystickActive, setIsJoystickActive] = useState(true)
  const [ethernetConnection, setEthernetConnection] = useState(true)
  const [connectionStatus, setConnectionStatus] = useState(supabase.matchChronology.connectionStatus.value)
  const isHideControlPanel = useSelector(selectIsHideControlPanel)
  
  useEffect(() => {
    supabase.matchChronology.connectionStatus.subscribe((data) => {
      setConnectionStatus(data)
    })
    
    const activeEthernet = () => {
      setEthernetConnection(true)
      console.debug({ event: 'internet connection recovered' })
    }
    const disableEthernet = () => {
      setEthernetConnection(false)
      console.error({ event: 'internet connection lost' })
    }
    
    window.addEventListener('online', activeEthernet)
    window.addEventListener('offline', disableEthernet)
    return () => {
      window.removeEventListener('online', activeEthernet)
      window.removeEventListener('offline', disableEthernet)
    }
  }, []);
  
  useEffect(() => {
    if (connectionStatus === 'SUBSCRIBED' && ethernetConnection) {
      setIsJoystickActive(true)
    } else {
      setIsJoystickActive(false)
    }
  }, [connectionStatus, ethernetConnection]);
  
  const onClickReset = useCallback(() => {
    dispatch(resetGameInfo())
    dispatch(resetTime())
  }, [dispatch]);
  
  const onClickSync = useCallback(() => {
    if (isSynchronization) return
    if (!match) return
    const matchId = match.id
    dispatch(syncGameInfo({ matchId }))
    dispatch(syncTime({ matchId }))
  }, [dispatch, isSynchronization, match]);
  
  const toggleHideControllPanel = useCallback(() => {
    dispatch(setHideControlPanel(!isHideControlPanel))
  }, [dispatch, isHideControlPanel]);
  
  return (
    isHideControlPanel ? (
      <OpenPanelWrapper
        opacity={0.8}
        position={'right'}
      >
        <HideButton
          color={'white'}
          onClick={toggleHideControllPanel}
          icon={<ArrowBracketIcon
            style={{ zIndex: 1000 }}
            width={15}
            height={15}
            direction={isHideControlPanel ? 'right' : 'left'}
          />}
        />
      </OpenPanelWrapper>
    ) : (
      <>
        <MainBlock
          className={'d-flex flex-column'}
          style={{ zIndex: 99 }}
        >
          <Indicator
            text={isJoystickActive ? 'Соединение с джойстиком установленно!' : 'Нет соединения с джойстиком!'}
            icon={<JoystickIcon/>}
            isActive={isJoystickActive}
          />
          <TeamsName/>
          <Score/>
          <Fouls/>
          <Period/>
          <Dashboard/>
          <Button
            color={'#D9B244'}
            onClick={onClickSync}
            className={classNames('flex-center', { 'loading': isSynchronization })}
          >
            {!isSynchronization && (
              <>
                <Text size={'medium'} color={'inherit'}>Синхронизировать</Text>
                <SyncIcon height={13} width={12} color={'white'}/>
              </>
            )
            }
          </Button>
          <Button
            color={'#686A79'}
            onClick={onClickReset}
            className={'flex-center'}
          >
            <Text size={'medium'} color={'inherit'}>Сбросить все настройки</Text>
            <CircleArrowCogwheelIcon/>
          </Button>
          <Team teamIndex={0}/>
          <Team teamIndex={1}/>
          <OpenPanelWrapper
            opacity={0.3}
            position={'left'}
          >
            <HideButton
              color={'white'}
              onClick={toggleHideControllPanel}
              icon={<ArrowBracketIcon style={{ zIndex: 1000 }} width={15} height={15} direction={'left'}/>}
            />
          </OpenPanelWrapper>
        </MainBlock>
      </>
    )
  );
}

export default Joystick;
