import { createAsyncThunk } from "@reduxjs/toolkit";
import supabase from "../../../core/supabase/supabase";
import {
  CopyBoard,
  CreateBoard,
  CreateTemplate,
  TemplateExtend,
  UpdateBoard,
  UpdateTemplate,
} from "../../../core/domain/interfaces/supabase/overlay/auxiliary-types";
import { Board } from "../../../core/domain/interfaces/supabase/overlay/entity";
import { Resolution } from "../../../ud-ui/components/fabric-canvas/types";

const PREFIX = 'templates';

export const fetchTemplates = createAsyncThunk<TemplateExtend[] | null, { tournamentId: number }>(
  `${PREFIX}/fetchTemplates`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.getOrCreateUserTemplate(payload.tournamentId);
    } catch (error) {
      console.error(`${PREFIX}/fetchUser error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);


export const createTemplate = createAsyncThunk<TemplateExtend, CreateTemplate>(
  `${PREFIX}/createTemplate`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.createTemplate(payload);
    } catch (error) {
      console.error(`${PREFIX}/createTemplate error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
)

export const deleteTemplate = createAsyncThunk<{ id: number }, { id: number }>(
  `${PREFIX}/deleteTemplate`,
  async (payload, { rejectWithValue }) => {
    try {
      await supabase.overlay.deleteTemplate(payload.id);
      return payload
    } catch (error) {
      console.error(`${PREFIX}/deleteTemplate error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
)

export const updateTemplate = createAsyncThunk<TemplateExtend, UpdateTemplate>(
  `${PREFIX}/updateTemplate`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.updateTemplate(payload);
    } catch (error) {
      console.error(`${PREFIX}/updateTemplate error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
)

export const createBoard = createAsyncThunk<Board, CreateBoard>(
  `${PREFIX}/createBoard`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.createBoard(payload);
    } catch (error) {
      console.error(`${PREFIX}/createBoard error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
)

export const deleteBoard = createAsyncThunk<{ id: number }, { id: number }>(
  `${PREFIX}/deleteBoard`,
  async (payload, { rejectWithValue }) => {
    try {
      await supabase.overlay.deleteBoard(payload.id);
      return payload
    } catch (error) {
      console.error(`${PREFIX}/deleteBoard error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
)

export const updateBoard = createAsyncThunk<Board, UpdateBoard>(
  `${PREFIX}/updateBoard`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.updateBoard(payload);
    } catch (error) {
      console.error(`${PREFIX}/updateBoard error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
)

export const copyTemplate = createAsyncThunk<TemplateExtend, {
  ownerId: number,
  templateId: number,
  name?: string,
  resolution?: Resolution
}>(
  `${PREFIX}/copyTemplate`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.copyTemplate(payload);
    } catch (error) {
      console.error(`${PREFIX}/createTemplateCopy error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const copyBoard = createAsyncThunk<Board, CopyBoard>(
  `${PREFIX}/copyBoard`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.copyBoard(payload);
    } catch (error) {
      console.error(`${PREFIX}/copyBoard error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);
