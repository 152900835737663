import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { TemplatesSliceState } from "./templates";
import {
  copyBoard,
  copyTemplate,
  createBoard,
  createTemplate,
  deleteBoard,
  deleteTemplate,
  fetchTemplates,
  updateBoard,
  updateTemplate,
} from "./actions";
import sortTemplates from "../../domain/utils/sortTemplates";
import { toast } from "react-toastify";
import sortBoards from "../../domain/utils/sortBoards";

type TemplateEditorReducerBuilder = ActionReducerMapBuilder<TemplatesSliceState>;

export function createFetchTemplatesReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(fetchTemplates.pending, (state) => {
    state.isLoading = true;
    state.isLoaded = false;
  });
  
  builder.addCase(fetchTemplates.fulfilled, (state, action) => {
    state.isLoading = false;
    state.isLoaded = true;
    const templates = action.payload
    if (!templates) {return;}
    
    const mappedTemplates = templates.map(template => {
      template.boards = sortBoards(template.boards)
      return template
    });
    
    state.templates = sortTemplates(mappedTemplates) as any
  });
  
  builder.addCase(fetchTemplates.rejected, (state) => {
    state.isLoading = false;
    state.isLoaded = true;
    
    toast.error('Не удалось загрузить шаблоны')
  });
}

export function createCreateTemplateReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(createTemplate.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
  });
  
  builder.addCase(createTemplate.fulfilled, (state, action) => {
    state.templates.push(action.payload as any)
    state.isProcessed = false;
    state.isFinished = true;
  });
  
  builder.addCase(createTemplate.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    
    toast.error('Не удалось создать шаблон')
  });
}

export function createDeleteTemplateReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(deleteTemplate.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
  });
  
  builder.addCase(deleteTemplate.fulfilled, (state, action) => {
    state.templates = state.templates.filter(t => t.id !== action.payload.id)
    state.activeTemplateId = undefined;
    state.isProcessed = false;
    state.isFinished = true;
  });
  
  builder.addCase(deleteTemplate.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    
    toast.error('Не удалось удалить шаблон')
  });
}

export function createUpdateTemplateReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(updateTemplate.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
  });
  
  builder.addCase(updateTemplate.fulfilled, (state, action) => {
    state.templates = state.templates.map(t => t.id === action.payload.id ? action.payload : t) as any
    state.isProcessed = false;
    state.isFinished = true;
  });
  
  builder.addCase(updateTemplate.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    
    toast.error('Не удалось обновить шаблон')
  });
}

export function createCreateBoardReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(createBoard.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
  });
  
  builder.addCase(createBoard.fulfilled, (state, action) => {
    state.templates = state.templates.map(template => {
      if (template.id === state.activeTemplateId) {
        template.boards.push(action.payload as any)
      }
      return template
    })
    
    state.isProcessed = false;
    state.isFinished = true;
  });
  
  builder.addCase(createBoard.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    
    toast.error('Не удалось создать табло')
  });
}

export function createDeleteBoardReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(deleteBoard.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
  });
  
  builder.addCase(deleteBoard.fulfilled, (state, action) => {
    state.templates = state.templates.map(template => {
      if (template.id === state.activeTemplateId) {
        template.boards = template.boards.filter(board => board.id !== action.payload.id)
      }
      return template
    })
    
    state.isProcessed = false;
    state.isFinished = true;
  });
  
  builder.addCase(deleteBoard.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    
    toast.error('Не удалось удалить табло')
  });
}

export function createUpdateBoardReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(updateBoard.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
  });
  
  builder.addCase(updateBoard.fulfilled, (state, action) => {
    state.templates = state.templates.map(template => {
      if (template.id === state.activeTemplateId) {
        template.boards = template.boards.map(board => board.id === action.payload.id ? action.payload : board) as any
      }
      return template
    })
    
    state.isProcessed = false;
    state.isFinished = true;
  });
  
  builder.addCase(updateBoard.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    
    toast.error('Не удалось обновить табло')
  });
}

export function createCopyTemplateReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(copyTemplate.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
  });
  
  builder.addCase(copyTemplate.fulfilled, (state, action) => {
      const template = action.payload
      template.boards = sortBoards(template.boards)
      state.templates.push(template as any)
      
      state.isProcessed = false;
      state.isFinished = true;
    },
  );
  
  builder.addCase(copyTemplate.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    toast.error('Не удалось создать копию')
  });
}

export function createCopyBoardReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(copyBoard.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
  });
  
  builder.addCase(copyBoard.fulfilled, (state, action) => {
    state.templates = state.templates.map(template => {
      if (template.id === action.payload.templateId) {
        template.boards.push(action.payload as any)
      }
      return template
    })
    
    state.isProcessed = false;
    state.isFinished = true;
  });
  
  builder.addCase(copyBoard.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    toast.error('Не удалось создать копию')
  });
}
